import { createContext, useContext, useEffect, useRef, useState } from 'react'
import {
  CurrentState,
  Game,
  GameLiveStatus,
  GameLiveStatusPeriodScore,
  LiveChange,
  LiveMessage,
  LiveMessageType,
  Market,
  Odd,
  ProviderState,
  TranslatedCountry,
  TranslatedGameCategory,
  TranslatedLeague,
  TranslatedSport,
} from '@arland-bmnext/api-data'
import { sortArray, upsert } from '../util/array'
import {
  filterUnavailableGames,
  MergedLiveState,
  removeGameMapEntry,
  removeMarketMapEntry,
  removeOddMapEntry,
  TranslatedLiveSportItem,
  updateGameMapEntry,
  updateMarketMapEntry,
  updateOddMapEntry,
} from '../util/live'
import { getSessionInformation } from '../lib/session'
import { oddsChangeService } from '../services/oddschange.service'
import { useLiveApiDomain, useMarketTypeTranslations } from '../lib/content'
import { marketTypeSpecifications } from '../util/market-type-specifications'
import { translateGameMarkets } from '../util/market'
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel,
} from '@microsoft/signalr'

type LiveContextProps = {
  connectionState?: HubConnectionState
  sessionId?: string
  languageId?: number
  sports?: TranslatedLiveSportItem[]
  leagues?: Map<string, TranslatedLeague>
  countries?: Map<string, TranslatedCountry>
  gameCategories?: Map<string, TranslatedGameCategory>
  liveGames?: Game[]
  providerStates?: any[]
  createLiveHubChannel?: () => void
  liveHubConnection?: HubConnection
  setLiveHubChannelFilter?: (gameFilter?: number[], marketTypeFilter?: number[]) => Promise<void>
  liveListActiveSportId?: number
  setLiveListActiveSportId?: (sportId: number) => void
}

const defaultLiveMarketTypes = marketTypeSpecifications.reduce((acc, x) => acc.concat(x.marketTypeIds), [])

let messageQueue: LiveMessage[] = []
const processLiveMessagesInterval = 300

let gameFilter: number[] = []
let marketTypeFilter: number[] = defaultLiveMarketTypes

export const LiveContext = createContext<LiveContextProps | undefined>(undefined)

export const useLiveContext = () => {
  const value = useContext(LiveContext)
  if (process.env.NODE_ENV !== 'production') {
    if (value === undefined) {
      throw new Error('useLiveContext must be wrapped in a <LiveProvider />')
    }
  }
  return value
}

const LiveProvider = ({ children, liveEnabled, language }) => {
  const marketTypeTranslations = useMarketTypeTranslations(language?.id)
  const marketTypeTranslationsRef = useRef(marketTypeTranslations)

  const liveApiBaseUrl = useLiveApiDomain()

  const [liveHubConnection, setLiveHubConnection] = useState<HubConnection>(null)

  const [liveGames, setLiveGames] = useState<Game[]>(undefined)

  const [providerStates, setProviderStates] = useState<ProviderState[]>([])
  const providerStatesRef = useRef(providerStates)

  const [mergedLiveState, setMergedLiveState] = useState(new MergedLiveState())
  const mergedLiveStateRef = useRef(mergedLiveState)

  const [liveListActiveSportId, setLiveListActiveSportId] = useState<number>(null)

  useEffect(() => {
    mergedLiveStateRef.current = mergedLiveState
    setLiveGames(sortArray(filterUnavailableGames(Array.from(mergedLiveState.games.values())), 'startDate', 'asc'))
  }, [mergedLiveState])

  useEffect(() => {
    providerStatesRef.current = providerStates
  }, [providerStates])

  useEffect(() => {
    let connection: HubConnection = null

    const connect = async () => {
      console.log('[LIVE] connect')
      connection = buildLiveHubConnection()
      setLiveHubConnection(connection)
      connection.on('OnMessageAsync', enqueueLiveMessage)

      await establishLiveHubConnection(connection)

      connection.onreconnected(async () => {
        console.log('[LIVE] Reconnected')
        await createLiveHubChannel(connection)
      })
    }

    if (liveEnabled && marketTypeTranslations && liveApiBaseUrl) {
      connect().catch(console.error)
    }

    return () => {
      connection?.off('OnMessageAsync', enqueueLiveMessage)
      connection?.stop()
    }
  }, [liveEnabled, marketTypeTranslations, liveApiBaseUrl])

  useEffect(() => {
    console.log('[LIVE] hub state change', liveHubConnection?.state)
    if (liveHubConnection?.state === HubConnectionState.Disconnected) {
      setMergedLiveState(new MergedLiveState())
    }
  }, [liveHubConnection?.state])

  useEffect(() => {
    const interval = setInterval(processLiveMessages, processLiveMessagesInterval)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    marketTypeTranslationsRef.current = marketTypeTranslations
  }, [marketTypeTranslations])

  const reset = () => {
    setLiveHubConnection(null)
    setProviderStates([])
    setMergedLiveState(new MergedLiveState())
  }

  const buildLiveHubConnection = () => {
    if (typeof window === 'undefined') {
      return
    }

    const hubUrl = liveApiBaseUrl + '/hub/livegames'

    const options: IHttpConnectionOptions = {
      transport: HttpTransportType.WebSockets | HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling,
      logMessageContent: true,
      logger: LogLevel.Error,
    }

    return new HubConnectionBuilder().withUrl(hubUrl, options).withAutomaticReconnect().build()
  }

  const establishLiveHubConnection = async (connection: HubConnection) => {
    console.log('[LIVE] establish LiveHub Connection')
    try {
      await connection.start()
      await createLiveHubChannel(connection)
    } catch (err) {
      console.log('[LIVE] SignalR Connection error', err)
      setTimeout(() => establishLiveHubConnection(connection), 5000)
    }
  }

  const createLiveHubChannel = async (connection: HubConnection) => {
    console.log('[LIVE] createLiveHubChannel')

    const { sessionId, languageId } = await getSessionInformation()
    await connection?.invoke('InvokeCreateChannel', sessionId, languageId, defaultLiveMarketTypes?.join(','))

    if (gameFilter || marketTypeFilter) {
      await connection?.invoke('InvokeUpdateDispatch', gameFilter?.join(','), marketTypeFilter?.join(','))
    }
  }

  const changeLiveHubChannelFilter = async () => {
    console.log('[LIVE] changeLiveHubChannelFilter', gameFilter, marketTypeFilter)

    if (liveHubConnection?.state === HubConnectionState.Connected) {
      await liveHubConnection?.invoke('InvokeUpdateDispatch', gameFilter?.join(','), marketTypeFilter?.join(','))
    }
  }

  const setLiveHubChannelFilter = async (
    _gameFilter: number[] = null,
    _marketTypeFilter: number[] = defaultLiveMarketTypes,
  ) => {
    console.log('[LIVE] setLiveHubChannelFilter')
    gameFilter = _gameFilter
    marketTypeFilter = _marketTypeFilter
    changeLiveHubChannelFilter()
  }

  const enqueueLiveMessage = (message: LiveMessage) => {
    messageQueue.push(message)
  }

  const processLiveMessages = () => {
    if (messageQueue.length > 0) {
      const messagesToProcess = [...messageQueue]
      const currentProviderStates = [...providerStatesRef.current]
      let currentLiveState = { ...mergedLiveStateRef.current }

      messageQueue = []

      for (let message of messagesToProcess) {
        currentLiveState = handleLiveMessage(currentLiveState, currentProviderStates, message)
      }

      currentLiveState = updateOpenGameCountForSports(currentLiveState)

      setMergedLiveState(currentLiveState)
      setProviderStates(currentProviderStates)
    }
  }

  const handleLiveMessage = (
    currentLiveState: MergedLiveState,
    currentProviderStates: ProviderState[],
    message: LiveMessage,
  ): MergedLiveState => {
    switch (message.type) {
      case LiveMessageType.Full:
        currentLiveState = onFullMessage(currentLiveState, message.full)
        break
      case LiveMessageType.Delta:
        onDeltaMessage(currentLiveState, message.delta)
        break
      case LiveMessageType.ProviderChange:
        onProviderChangeMessage(currentProviderStates, message.providerState)
        break
      case LiveMessageType.ChannelFailed:
        console.log('[LIVE] ChannelFailed message received.')
        break
      case LiveMessageType.ConnectionLoss:
        console.log('[LIVE] ConnectionLoss message received.')
        break
      case LiveMessageType.ConnectionRecover:
        console.log('[LIVE] ConnectionRecover message received.')
        break
      default:
        console.log('[LIVE] Unknown message received which cannot be handled.', message.type)
        break
    }

    return currentLiveState
  }

  const onFullMessage = (currentLiveState: MergedLiveState, state: CurrentState): MergedLiveState => {
    currentLiveState = new MergedLiveState()

    state?.games?.forEach((game) => {
      translateGameMarkets(game, marketTypeTranslationsRef.current)
      updateGameMapEntry(currentLiveState, game)
    })

    state.sports?.forEach((sport) => {
      currentLiveState.sports.set(sport.id.toString(), sport as TranslatedLiveSportItem)
    })

    state.leagues?.forEach((league) => {
      currentLiveState.leagues.set(league.id.toString(), league)
    })

    state.gameCategories?.forEach((gameCategory) => {
      currentLiveState.gameCategories.set(gameCategory.id.toString(), gameCategory)
    })

    state.countries?.forEach((country) => {
      currentLiveState.countries.set(country.id.toString(), country)
    })

    return currentLiveState
  }

  const onDeltaMessage = (currentLiveState: MergedLiveState, delta: LiveChange) => {
    if (delta?.games?.new != null) onDeltaNewGames(currentLiveState, delta.games.new)
    if (delta?.markets?.new != null) onDeltaNewMarkets(currentLiveState, delta.markets.new)
    if (delta?.odds?.new != null) onDeltaNewOdds(currentLiveState, delta.odds.new)
    if (delta?.liveStatus?.new != null) onDeltaNewLiveStatus(currentLiveState, delta.liveStatus.new)
    if (delta?.periodScores?.new != null) onDeltaNewPeriodScores(currentLiveState, delta.periodScores.new)

    if (delta?.games?.changed != null) onDeltaChangedGames(currentLiveState, delta.games.changed)
    if (delta?.markets?.changed != null) onDeltaChangedMarkets(currentLiveState, delta.markets.changed)
    if (delta?.odds?.changed != null) onDeltaChangedOdds(currentLiveState, delta.odds.changed)
    if (delta?.liveStatus?.changed != null) onDeltaChangedLiveStatus(currentLiveState, delta.liveStatus.changed)
    if (delta?.periodScores?.changed != null) onDeltaChangedPeriodScores(currentLiveState, delta.periodScores.changed)

    if (delta?.games?.removed != null) onDeltaRemovedGames(currentLiveState, delta.games.removed)
    if (delta?.markets?.removed != null) onDeltaRemovedMarkets(currentLiveState, delta.markets.removed)
    if (delta?.odds?.removed != null) onDeltaRemovedOdds(currentLiveState, delta.odds.removed)

    if (delta?.sports != null) onDeltaSports(currentLiveState, delta.sports)

    if (delta?.leagues != null) onDeltaLeagues(currentLiveState, delta.leagues)
  }

  const updateOpenGameCountForSports = (currentLiveState: MergedLiveState): MergedLiveState => {
    const openGameCountMap = new Map<number, number>()
    const games = filterUnavailableGames(Array.from(currentLiveState.games.values()))

    for (let game of games) {
      let currentCount = openGameCountMap.get(game.sportId)
      if (currentCount == null) currentCount = 1
      else currentCount++
      openGameCountMap.set(game.sportId, currentCount)
    }

    for (let [key, value] of openGameCountMap) {
      const sportItem = currentLiveState.sports.get(key.toString())
      if (sportItem == null) continue
      sportItem.openGameCount = value
      currentLiveState.sports.set(key.toString(), sportItem)
    }

    return currentLiveState
  }

  const onDeltaSports = (currentLiveState: MergedLiveState, sports: TranslatedSport[]) => {
    sports.forEach((sport) => {
      currentLiveState.sports.set(sport.id.toString(), sport as TranslatedLiveSportItem)
    })
  }

  const onDeltaLeagues = (currentLiveState: MergedLiveState, leagues: TranslatedLeague[]) => {
    leagues.forEach((league) => {
      currentLiveState.leagues.set(league.id.toString(), league)
    })
  }

  const onDeltaNewGames = (currentLiveState: MergedLiveState, newGames: Game[]) => {
    for (let newGame of newGames) {
      updateGameMapEntry(currentLiveState, newGame)
      translateGameMarkets(newGame, marketTypeTranslationsRef.current)
    }
  }

  const onDeltaNewMarkets = (currentLiveState: MergedLiveState, newMarkets: Market[]) => {
    for (let newMarket of newMarkets) {
      const gameKey = newMarket.gameId.toString()
      const game = currentLiveState.games.get(gameKey)

      if (game) {
        upsert(game.markets, newMarket)
        updateMarketMapEntry(currentLiveState, newMarket, gameKey)
        translateGameMarkets(game, marketTypeTranslationsRef.current)
      }
    }
  }

  const onDeltaNewOdds = (currentLiveState: MergedLiveState, newOdds: Odd[]) => {
    for (let newOdd of newOdds) {
      if (newOdd.id === 0) continue

      const oddKey = newOdd.id.toString()
      const marketKey = newOdd.marketId.toString()
      const gameKey = currentLiveState.gameForMarketLookup.get(marketKey)

      currentLiveState.marketForOddLookup.set(oddKey, marketKey)

      const game = { ...currentLiveState.games.get(gameKey) }
      const market = game?.markets?.find((market) => market.id.toString() === marketKey)

      if (market) {
        upsert(market.odds, newOdd)
        updateOddMapEntry(currentLiveState, newOdd, marketKey)
      }
    }
  }

  const onDeltaNewLiveStatus = (currentLiveState: MergedLiveState, newLiveStatus: GameLiveStatus[]) => {
    for (let newStatus of newLiveStatus) {
      const liveStatusKey = newStatus.id.toString()

      const gameKey = currentLiveState.gameForLiveStatusLookup.get(liveStatusKey)
      if (gameKey == null) continue

      const game = currentLiveState.games.get(gameKey)
      if (game) {
        game.liveStatus = newStatus
      }
    }
  }

  const onDeltaNewPeriodScores = (currentLiveState: MergedLiveState, newPeriodScores: GameLiveStatusPeriodScore[]) => {
    for (let newPeriodScore of newPeriodScores) {
      const periodScoreKey = newPeriodScore.id.toString()

      const liveStatusKey = currentLiveState.liveStatusForPeriodScoreLookup.get(periodScoreKey)
      if (liveStatusKey == null) continue

      const gameKey = currentLiveState.gameForLiveStatusLookup.get(liveStatusKey)
      if (gameKey == null) continue

      currentLiveState.games.get(gameKey)?.liveStatus?.periodScores?.push(newPeriodScore)
    }
  }

  const onDeltaChangedGames = (currentLiveState: MergedLiveState, changedGames: Game[]) => {
    for (let changedGame of changedGames) {
      const gameKey = changedGame.id.toString()
      const game = { ...currentLiveState.games.get(gameKey) }

      if (game) {
        delete changedGame.liveStatus
        delete changedGame.markets
        delete changedGame.competitors
        currentLiveState.games.set(gameKey, { ...game, ...changedGame })
      }
    }
  }

  const onDeltaChangedMarkets = (currentLiveState: MergedLiveState, changedMarkets: Market[]) => {
    for (let changedMarket of changedMarkets) {
      const gameKey = changedMarket.gameId.toString()

      const game = currentLiveState.games.get(gameKey)
      if (game == null) continue

      const currentMarket = game.markets?.find((market) => market.id === changedMarket.id)
      const marketIndex = game.markets?.indexOf(currentMarket)

      if (currentMarket) {
        delete changedMarket.odds
        delete changedMarket.name
        game.markets[marketIndex] = { ...currentMarket, ...changedMarket }
      }
    }
  }

  const onDeltaChangedOdds = (currentLiveState: MergedLiveState, changedOdds: Odd[]) => {
    for (let changedOdd of changedOdds) {
      const oddKey = changedOdd.id.toString()
      const marketKey = currentLiveState.marketForOddLookup.get(oddKey)
      const gameKey = currentLiveState.gameForMarketLookup.get(marketKey)

      const game = currentLiveState.games.get(gameKey)
      if (game == null) continue

      const market = game.markets?.find((market) => market.id === changedOdd.marketId)
      const marketIndex = game.markets?.indexOf(market)
      if (market == null) continue

      const odd = market.odds?.find((odd) => odd.id === changedOdd.id)
      const oddIndex = market.odds?.indexOf(odd)

      if (odd) {
        game.markets[marketIndex].odds[oddIndex] = changedOdd

        oddsChangeService.oddsChange(changedOdd)
      }
    }
  }

  const onDeltaChangedLiveStatus = (currentLiveState: MergedLiveState, changedLiveStatus: GameLiveStatus[]) => {
    for (let changedStatus of changedLiveStatus) {
      const liveStatusKey = changedStatus.id.toString()

      const gameKey = currentLiveState.gameForLiveStatusLookup.get(liveStatusKey)
      if (gameKey == null) continue

      const game = currentLiveState.games.get(gameKey)
      if (game) {
        delete changedStatus.periodScores
        game.liveStatus = { ...game.liveStatus, ...changedStatus }
      }
    }
  }

  const onDeltaChangedPeriodScores = (
    currentLiveState: MergedLiveState,
    changedPeriodScores: GameLiveStatusPeriodScore[],
  ) => {
    for (let changedPeriodScore of changedPeriodScores) {
      const periodScoreKey = changedPeriodScore.id.toString()

      const liveStatusKey = currentLiveState.liveStatusForPeriodScoreLookup.get(periodScoreKey)
      if (liveStatusKey == null) continue

      const gameKey = currentLiveState.gameForLiveStatusLookup.get(liveStatusKey)
      if (gameKey == null) continue

      const game = currentLiveState.games.get(gameKey)
      if (game) {
        let periodScoreIndex = 0
        let existingPeriodScore = null
        for (let periodScore of game.liveStatus?.periodScores) {
          if (periodScore.id === changedPeriodScore.id) {
            existingPeriodScore = periodScore
            break
          }
          periodScoreIndex++
        }

        if (existingPeriodScore == null) {
          game.liveStatus?.periodScores?.push(changedPeriodScore)
        } else {
          game.liveStatus.periodScores[periodScoreIndex] = changedPeriodScore
        }
      }
    }
  }

  const onDeltaRemovedGames = (currentLiveState: MergedLiveState, removedGames: number[]) => {
    for (let removedGame of removedGames) {
      removeGameMapEntry(currentLiveState, removedGame)
    }
  }

  const onDeltaRemovedMarkets = (currentLiveState: MergedLiveState, removedMarkets: number[]) => {
    for (let removedMarket of removedMarkets) {
      const marketKey = removedMarket.toString()
      const gameKey = currentLiveState.gameForMarketLookup.get(marketKey)

      removeMarketMapEntry(currentLiveState, removedMarket)

      const game = currentLiveState.games.get(gameKey)
      const gameMarkets = game?.markets

      if (gameMarkets) {
        game.markets = gameMarkets.filter((market) => market.id !== removedMarket)
      }
    }
  }

  const onDeltaRemovedOdds = (currentLiveState: MergedLiveState, removedOdds: number[]) => {
    for (let removedOdd of removedOdds) {
      const oddKey = removedOdd.toString()
      const marketKey = currentLiveState.marketForOddLookup.get(oddKey)
      const gameKey = currentLiveState.gameForMarketLookup.get(marketKey)

      removeOddMapEntry(currentLiveState, removedOdd)

      const game = currentLiveState.games.get(gameKey)
      const market = game?.markets?.find((market) => market.id.toString() === marketKey)
      const marketIndex = game?.markets?.indexOf(market)

      if (market?.odds) {
        game.markets[marketIndex].odds = market.odds.filter((odd) => odd.id !== removedOdd)
      }
    }
  }

  const onProviderChangeMessage = (currentProviderStates: ProviderState[], providerState: ProviderState) => {
    upsert(currentProviderStates, providerState)
  }

  const value = {
    connectionState: liveHubConnection?.state,
    liveGames,
    sports: sortArray(Array.from(mergedLiveState.sports.values()), 'sortKey', 'desc'),
    leagues: mergedLiveState.leagues,
    gameCategories: mergedLiveState.gameCategories,
    countries: mergedLiveState.countries,
    providerStates,
    setLiveHubChannelFilter,
    liveListActiveSportId,
    setLiveListActiveSportId,
  }

  return <LiveContext.Provider value={value}>{children}</LiveContext.Provider>
}

export default LiveProvider
