export type MarketTypeSpecification = {
  marketTypeIds: number[]
  name: string
  translations?: TranslatedItem[]
  outcomes: MarketTypeOutcomeSpecification[]
}

export type MarketTypeOutcomeSpecification = {
  name: string
  oddTemplateIds?: number[]
  translations?: TranslatedItem[]
  specialValueName?:
    | 'specialIntValue1'
    | 'specialIntValue2'
    | 'specialIntValue3'
    | 'specialSingleValue1'
    | 'specialSingleValue2'
    | 'specialSingleValue3'
    | 'specialStringValue1'
    | 'specialStringValue2'
    | 'specialStringValue3'
}

export type TranslatedItem = {
  languageId: number
  name: string
}

export const outrightDefaultMarketTypeId = 492

export const marketTypeSpecifications: MarketTypeSpecification[] = [
  {
    marketTypeIds: [17],
    name: 'Total',
    outcomes: [
      { name: 'T', specialValueName: 'specialSingleValue1' },
      {
        name: 'Over',
        translations: [
          { languageId: 1, name: 'Over' },
          { languageId: 2, name: 'Über' },
          { languageId: 21, name: 'Mais' },
        ],
        oddTemplateIds: [2877],
      },
      {
        name: 'Under',
        translations: [
          { languageId: 1, name: 'Under' },
          { languageId: 2, name: 'Unter' },
          { languageId: 21, name: 'Menos' },
        ],
        oddTemplateIds: [2850],
      },
    ],
  },
  {
    marketTypeIds: [1, 1028],
    name: 'Who will win the match?',
    translations: [
      { languageId: 1, name: 'Who will win?' },
      { languageId: 2, name: 'Wer wird gewinnen?' },
      { languageId: 21, name: 'Quem vence o jogo?' },
    ],
    outcomes: [
      {
        name: '1',
        oddTemplateIds: [2868, 3584],
      },
      {
        name: 'X',
        oddTemplateIds: [2940, 3585],
      },
      {
        name: '2',
        oddTemplateIds: [2941, 3586],
      },
    ],
  },
  {
    marketTypeIds: [175],
    name: 'Winner',
    translations: [
      { languageId: 1, name: 'Who will win?' },
      { languageId: 2, name: 'Wer wird gewinnen?' },
      { languageId: 21, name: 'Quem vence o jogo?' },
    ],
    outcomes: [
      {
        name: '1',
        oddTemplateIds: [586],
      },
      {
        name: '2',
        oddTemplateIds: [587],
      },
    ],
  },
  {
    marketTypeIds: [206],
    name: 'Winner (incl. overtime)',
    translations: [
      { languageId: 1, name: 'Winner (incl. OT)' },
      { languageId: 2, name: 'Gewinner (inkl. Verl.)' },
      { languageId: 21, name: 'Vencedor (incl. OT)' },
    ],
    outcomes: [
      {
        name: '1',
        oddTemplateIds: [671],
      },
      {
        name: '2',
        oddTemplateIds: [672],
      },
    ],
  },
  {
    marketTypeIds: [10],
    name: 'Double Chance',
    translations: [
      { languageId: 1, name: 'Double Chance' },
      { languageId: 2, name: 'Doppelte Chance' },
      { languageId: 21, name: 'Chance Dupla' },
    ],
    outcomes: [
      {
        name: '1 or Draw',
        translations: [
          { languageId: 1, name: '1 or X' },
          { languageId: 2, name: '1 oder X' },
          { languageId: 21, name: '1 ou X' },
        ],
        oddTemplateIds: [2861],
      },
      {
        name: '1 or 2',
        translations: [
          { languageId: 1, name: '1 or 2' },
          { languageId: 2, name: '1 oder 2' },
          { languageId: 21, name: '1 ou 2' },
        ],
        oddTemplateIds: [2888],
      },
      {
        name: '2 or Draw',
        translations: [
          { languageId: 1, name: '2 or X' },
          { languageId: 2, name: '2 oder X' },
          { languageId: 21, name: '2 ou X' },
        ],
        oddTemplateIds: [2934],
      },
    ],
  },
  {
    marketTypeIds: [27],
    name: 'Both teams to score?',
    translations: [
      { languageId: 1, name: 'Both teams to score?' },
      { languageId: 2, name: 'Punkten beide Teams?' },
      { languageId: 21, name: 'As duas equipes marcam gols?' },
    ],
    outcomes: [
      {
        name: 'Yes',
        translations: [
          { languageId: 1, name: 'Yes' },
          { languageId: 2, name: 'Ja' },
          { languageId: 21, name: 'Sim' },
        ],
        oddTemplateIds: [2865],
      },
      {
        name: 'No',
        translations: [
          { languageId: 1, name: 'No' },
          { languageId: 2, name: 'Nein' },
          { languageId: 21, name: 'Não' },
        ],
        oddTemplateIds: [2891],
      },
    ],
  },
  {
    marketTypeIds: [24],
    name: 'Odd or even goals?',
    translations: [
      { languageId: 1, name: 'Odd or even goals?' },
      { languageId: 2, name: 'Gerade oder ungerade Toranzahl?' },
      { languageId: 21, name: 'Número de gols: ímpar ou par?' },
    ],
    outcomes: [
      {
        name: 'Odd',
        translations: [
          { languageId: 1, name: 'Odd' },
          { languageId: 2, name: 'Ungerade' },
          { languageId: 21, name: 'Ímpar' },
        ],
        oddTemplateIds: [2858],
      },
      {
        name: 'Even',
        translations: [
          { languageId: 1, name: 'Even' },
          { languageId: 2, name: 'Gerade' },
          { languageId: 21, name: 'Par' },
        ],
        oddTemplateIds: [2885],
      },
    ],
  },
  {
    marketTypeIds: [9],
    name: 'Last goal?',
    translations: [
      { languageId: 1, name: 'Who scores the last goal' },
      { languageId: 2, name: 'Wer schießt das letzte Tor?' },
      { languageId: 21, name: 'Qual equipe vai marcar o último gol?' },
    ],
    outcomes: [
      {
        name: '1',
        oddTemplateIds: [24],
      },
      {
        name: 'None',
        translations: [
          { languageId: 1, name: 'No goal' },
          { languageId: 2, name: 'Kein Tor' },
          { languageId: 21, name: 'nenhuma' },
        ],
        oddTemplateIds: [25],
      },
      {
        name: '2',
        oddTemplateIds: [26],
      },
    ],
  },
  {
    marketTypeIds: [11],
    name: 'Draw no bet',
    translations: [
      { languageId: 1, name: 'Draw? No bet!' },
      { languageId: 2, name: 'Unentschieden? Geld zurück!' },
      { languageId: 21, name: 'Empate devolve a aposta' },
    ],
    outcomes: [
      {
        name: '1',
        oddTemplateIds: [30],
      },
      {
        name: '2',
        oddTemplateIds: [31],
      },
    ],
  },
  {
    marketTypeIds: [186],
    name: 'Odd or even games?',
    translations: [
      { languageId: 1, name: 'Odd or even games?' },
      { languageId: 2, name: 'Gerade oder ungerade Spielanzahl?' },
      { languageId: 21, name: 'Número de games: ímpar ou par?' },
    ],
    outcomes: [
      {
        name: 'Odd',
        translations: [
          { languageId: 1, name: 'Odd' },
          { languageId: 2, name: 'Ungerade' },
          { languageId: 21, name: 'Ímpar' },
        ],
        oddTemplateIds: [606],
      },
      {
        name: 'Even',
        translations: [
          { languageId: 1, name: 'Even' },
          { languageId: 2, name: 'Gerade' },
          { languageId: 21, name: 'Par' },
        ],
        oddTemplateIds: [607],
      },
    ],
  },
  {
    marketTypeIds: [7],
    name: 'Rest of match',
    translations: [
      { languageId: 1, name: 'Rest of match winner?' },
      { languageId: 2, name: 'Restzeit Gewinner?' },
      { languageId: 21, name: 'Quem ganha o resto do jogo?' },
    ],
    outcomes: [
      {
        name: '1',
        oddTemplateIds: [18],
      },
      {
        name: 'X',
        oddTemplateIds: [19],
      },
      {
        name: '2',
        oddTemplateIds: [20],
      },
    ],
  },
  {
    marketTypeIds: [8],
    name: 'Next goal',
    translations: [
      { languageId: 1, name: 'Who scores next?' },
      { languageId: 2, name: 'Wer punktet als nächstes?' },
      { languageId: 21, name: 'Quem marca o próximo gol?' },
    ],
    outcomes: [
      {
        name: '1',
        oddTemplateIds: [2851],
      },
      {
        name: 'X',
        translations: [
          { languageId: 1, name: 'None' },
          { languageId: 2, name: 'Keiner' },
          { languageId: 21, name: 'nenhuma' },
        ],
        oddTemplateIds: [2878],
      },
      {
        name: '2',
        oddTemplateIds: [2913],
      },
    ],
  },
  {
    marketTypeIds: [189],
    name: 'Next set',
    translations: [
      { languageId: 1, name: 'Who scores next?' },
      { languageId: 2, name: 'Wer punktet als nächstes?' },
      { languageId: 21, name: 'Quem marca o próximo gol?' },
    ],
    outcomes: [
      {
        name: '1',
        oddTemplateIds: [612],
      },
      {
        name: '2',
        oddTemplateIds: [613],
      },
    ],
  },
]
